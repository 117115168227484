/*
 * Third party
 */
 ////= ../../bower_components/jquery/dist/jquery.js


/*
 * Custom
 */
$(function() {
	// Убираем placeholder у select

	$(".calc__input_placeholder").one("change", function() {
		$(this).removeClass("calc__input_placeholder");
	});
});